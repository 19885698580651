<template>
  <div>
    <Row
      :gutter="8"
      class="m-b-5"
    >
      <i-col span="3">
        <Select
          v-model="query.assetId"
          placeholder="资产"
          @on-change="handleChangeAsset"
        >
          <Option
            v-for="item in companyAssetArray"
            :key="'asset_'+item.id"
            :value="item.id"
          >{{item.name}}</Option>
        </Select>
      </i-col>
      <i-col span="3">
        <Select
          v-model="query.stationId"
          placeholder="站点"
          :clearable="true"
        >
          <Option
            v-for="item in stationArray"
            :key="'station_'+item.id"
            :value="item.id"
          >{{item.name}}</Option>
        </Select>
      </i-col>
      <i-col span="3">
        <Select
          v-model="query.position"
          placeholder="位置"
          :clearable="true"
        >
          <Option
            v-for="item in positionArray"
            :key="'position_'+item.value"
            :value="item.value"
          >{{item.name}}</Option>
        </Select>
      </i-col>
      <i-col
        span="3"
        class="m-b-5"
      >
        <DatePicker
          placeholder="最后作业日期"
          v-model="query.latestActionDate"
          type="date"
          style="width:100%"
          @on-change="changeActionDate"
        ></DatePicker>
      </i-col>
      <!-- <i-col
        span="3"
        class="m-b-5"
      >
        <DatePicker
          placeholder="结束时间"
          v-model="query.endDate"
          type="date"
          style="width:100%"
          :options="endDateOptions"
          @on-change="changeEndDate"
        ></DatePicker>
      </i-col> -->
      <i-col
        span="6"
        class="m-b-5"
      >
        <Input
          search
          enter-button
          placeholder="关键词"
          v-model="query.keyword"
          @on-search="handleSearch"
        />
      </i-col>
    </Row>
    <div class="m-b-10">
      <Button
        type="primary"
        size="small"
        class="m-r-5"
        :disabled="selectedResourceIdArray.length===0&&selectedSideArray.length!==1"
        @click="handleSetUninstall"
      >设置下刊内容</Button>
      <Button
        type="success"
        size="small"
        @click="handleSubmitUninstall"
      >提交下刊</Button>
    </div>
    <div>
      <Table
        stripe
        :data="resultData.list"
        :columns="tableColumns"
        @on-selection-change="handleSelection"
      ></Table>
      <div class="paging_style">
        <Page size="small"
          :total="resultData.totalRow"
          :page-size="query.pageSize"
          show-total
          show-elevator
          :current="query.pageNumber"
          @on-change="changePage"
        ></Page>
      </div>
    </div>
    <Modal
      v-model="visiblePreview"
      width="1000"
      :footer-hide="true"
    >
      <p
        slot="header"
        class="text-center"
      >{{fileName}}</p>
      <img-light-box ref="imgLightBox"></img-light-box>
    </Modal>
  </div>
</template>

<script>
import { companyMixin } from '@/assets/mixins/company'
// import { getStationByAssetIds } from '@/api/product/station'
import { getRimStationByAssetIds } from '@/api/rim/station'
import { getPositions } from '@/api/product/resource'
import { GetCurrentSchedule } from '@/utils/dateFormat'
import { submitUninstall } from '@/api/msp/uninstall'

import ImgLightBox from '@/components/common/ImgLightBox'
export default {
  mixins: [companyMixin],
  components: {
    ImgLightBox
  },
  data () {
    return {
      visiblePreview: false,
      query: {
        assetId: null,
        stationId: null,
        position: null,
        resourcetypeId: null,
        latestActionDate: '',
        // uninstallapply: null,
        keyword: '',
        pageSize: 50,
        pageNumber: 1
      },
      companyId: this.$store.getters.token.userInfo.companyId,
      stationArray: [],
      positionArray: [],
      // selectedSideArray: [],
      fileName: '',
      tableColumns: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: '资源编号',
          key: 'resourceCode',
          render: (h, params) => {
            return h('span', { style: { color: params.row.uninstallapply ? '#FA8B59' : '#fff' } }, params.row.resourceCode)
          }
        },
        {
          renderHeader: (h, params) => {
            if (this.productType === 5) {
              return h('span', '所属站点/站点类型')
            } else if (this.productType === 3) {
              return h('span', '列车类型')
            } else {
              return h('span', '所属站台')
            }
          },
          key: 'stationName',
          width: 100,
          render: (h, params) => {
            if (this.productType === 5) {
              return h('span', { style: { color: params.row.uninstallapply ? '#FA8B59' : '#fff' } }, params.row.stationName + '[' + params.row.stationTypeName + ']')
            } else {
              return h('span', { style: { color: params.row.uninstallapply ? '#FA8B59' : '#fff' } }, params.row.stationName)
            }
          }
        },
        {
          title: '画面数',
          key: 'side'
        },
        {
          title: '投放档期',
          key: 'schedule',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                GetCurrentSchedule(params.row.startDate, params.row.endDate)
              )
            ])
          }
        },
        {
          title: '下刊时间',
          key: 'actionDate',
          render: (h, params) => {
            // if (params.row.endDate <= params.row.uninstallActionDate) {
            return h('div', [
              h(
                'span',
                params.row.uninstallActionDate
              )
            ])
            // } else {
            //   return h('div', [
            //     h(
            //       'span',
            //       {
            //         style: {
            //           color: '#ed4014'
            //         }
            //       },
            //       params.row.uninstallActionDate
            //     )
            //   ])
            // }
          }
        },
        {
          title: '当前画面',
          render: (h, params) => {
            if (params.row.oldFileKeyList && params.row.oldFileKeyList.length > 0) {
              return h('div', [
                h(
                  'img',
                  {
                    domProps: {
                      src: params.row.oldFileKeyList[0] + '?x-oss-process=image/resize,w_64'
                    },
                    on: {
                      click: () => {
                        const postData = {
                          startIndex: 0,
                          imgList: params.row.oldFileKeyList
                        }
                        this.fileName = params.row.stationName
                        this.$refs.imgLightBox.init(postData)
                        this.visiblePreview = true
                      }
                    },
                    style: {
                      width: '64px'
                    }
                  }
                )
              ])
            } else {
              return h('span', '暂无画面')
            }
          }
        },
        {
          title: '下刊替换画面',
          key: 'currentScrren',
          render: (h, params) => {
            if (params.row.uninstallFileKeyList && params.row.uninstallFileKeyList.length > 0) {
              return h('div', [
                h(
                  'img',
                  {
                    domProps: {
                      src: params.row.uninstallFileKeyList[0] + '?x-oss-process=image/resize,w_64'
                    },
                    on: {
                      click: () => {
                        const postData = {
                          startIndex: 0,
                          imgList: params.row.uninstallFileKeyList
                        }
                        this.fileName = params.row.stationName
                        this.$refs.imgLightBox.init(postData)
                        this.visiblePreview = true
                      }
                    },
                    style: {
                      width: '64px'
                    }
                  }
                )
              ])
            } else {
              return h('span', '暂无画面')
            }
          }
        },
        {
          title: '下刊名称',
          key: 'uninstallScreenName'
        }
        // {
        //   title: '下刊备注',
        //   key: 'remark'
        // },
        // {
        //   title: '操作',
        //   render: (h, param) => {
        //     return h('a', {
        //       on: {
        //         click: () => {
        //           // this.SetScreenModal()
        //           this.SetScreens = true
        //           this.setMaxSide = param.row.side
        //           this.selectedTaskIds = [param.row.taskId]
        //           this.UninstallData.isCharge = true
        //         }
        //       }
        //     }, '设置')
        //   }
        // }
        // {
        //   type: 'expand',
        //   width: 50,
        //   render: (h, params) => {
        //     return h(expandRow, {
        //       props: {
        //         row: params.row
        //       }
        //     })
        //   }
        // }
      ]
    }
  },
  computed: {
    selectedResourcetypeId: {
      get () {
        return this.$store.state.workOrder.selectedResourcetypeId
      },
      set (val) {
        this.$store.commit('set_selected_resourcetype_id', val)
      }
    },
    resultData () {
      return this.$store.state.workOrder.uninstallData
    },
    selectedResourceIdArray: {
      get () {
        return this.$store.state.workOrder.selectedResourceIdArray
      },
      set (val) {
        this.$store.commit('set_selected_resourceId_array', val)
      }
    },
    selectedSideArray: {
      get () {
        return this.$store.state.workOrder.selectedSideArray
      },
      set (val) {
        this.$store.commit('set_selected_side_array', val)
      }
    }
  },
  created () {
    this.getCompanyAssetArray()
    this.$store.commit('set_uninstall_pic_type', 1)
  },
  destroyed () {
    this.selectedResourcetypeId = null
    this.selectedResourceIdArray = []
    this.selectedSideArray = []
  },
  methods: {
    /**
     * 获取列表数据
     */
    getTableData () {
      this.query.startDate = this.startDate
      this.query.endDate = this.endDate
      this.query.resourcetypeId = this.selectedResourcetypeId
      this.selectedResourceIdArray = []
      this.$store.dispatch('getUninstallData', this.query)
    },
    changePage (curPage) {
      this.query.pageNumber = curPage
      this.getTableData()
    },
    changeActionDate (date) {
      this.query.latestActionDate = date
    },
    handleChangeAsset () {
      this.query.staitonId = null
      this.query.position = null
      this.selectedResourcetypeId = null
      this.$store.commit('set_selected_assetId', this.query.assetId)
      this.handleSearch()
      this.getStationData()
      this.getPositionData()
    },
    getStationData () {
      getRimStationByAssetIds({ assetIds: JSON.stringify([this.query.assetId]) }).then(res => {
        if (res && !res.errcode) {
          this.stationArray = res
        } else {
          this.stationArray = []
        }
      })
    },
    getPositionData () {
      const assetIds = []
      assetIds.push(this.query.assetId)
      const data = {
        assetIds: JSON.stringify(assetIds)
      }
      getPositions(data).then(res => {
        this.positionArray = res
      })
    },
    handleSearch () {
      this.query.pageNumber = 1
      delete this.query.resourcetypeId
      this.$store.dispatch('getUninstallResourceData', this.query)
      this.getTableData()
    },
    handleSelection (selections) {
      const sideArray = selections.map(x => x.side)
      this.selectedSideArray = Array.from(new Set(sideArray))

      if (this.selectedSideArray.length > 1) {
        this.$Notice.warning({ desc: '批量设置请选择画面数相同的资源' })
        this.selectedResourceIdArray = []
      } else {
        this.selectedResourceIdArray = selections.map(x => x.resourceId)
      }
    },
    handleSetUninstall () {
      this.$store.commit('set_left_component', 'UninstallSetScreen')
      this.$store.commit('set_show_left_block', true)
    },
    handleSubmitUninstall () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要提交下刊设置？',
        onOk: () => {
          submitUninstall().then(res => {
            if (res && res.errcode === 0) {
              this.handleSearch()
              this.$Notice.success({ desc: '操作成功' })
            }
          })
        }
      })
    }
  },
  watch: {
    companyAssetArray: {
      deep: true,
      immediate: true,
      handler (val) {
        if (val && val.length) {
          this.query.assetId = val[0].id
          this.$store.commit('set_selected_assetId', this.query.assetId)
          this.handleSearch()
          this.getStationData()
          this.getPositionData()
        }
      }
    }
  }
}
</script>
